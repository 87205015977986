body {
  margin: 0;
  padding: 0 !important;
}

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}

header {
  padding-right: 0 !important;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.drawer-item p,
.drawer-item {
  font-size: 16px !important;
}

.help-page {
  max-width: 900px;
  text-align: left;
  padding: 20px;
  padding-left: calc(50% - 450px);
}

@media screen and (max-width: 960px) {
  .help-page {
    padding: 20px;
  }
}

.fix-link {
  text-decoration: none;
}

.view-all-posts {
  text-decoration: none;
}

h6.MuiTypography-root.MuiTypography-h6.MuiTypography-gutterBottom {
  color: grey;
  font-weight: 400;
}
